import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import LoginForm from './login';
import RegisterForm from './register';
import LoggedinForm from './loggedin';
import logo from './images/tencent-cloud-logo.png';

const App = () => {
  return (
    <div className="App">
      <div className="App-header">
        <div className="logo-container">
          <img src={logo} alt="Tencent Cloud Logo" className="App-logo" />
        </div>
        <div className="header-container">
          <h1>Login Page</h1>
        </div>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginForm />} /> 
          <Route path="/register" element={<RegisterForm />} /> 
          <Route path="/loggedin" element={<LoggedinForm />} /> 
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
