// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.header-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 10px; /* Optional spacing between columns */
}

.header-container {
  display: flex;
  justify-content: left;
}

.logo-container {
  display: flex;
  justify-content: right;
}

h1 {
  font-size: 30px;
  color: #1890ff;
  text-align: center; /* Center align the heading */
  margin-top: 30px; 
  margin-bottom: 30px; /* Add some space below the heading */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color:#ffffff;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 150px; /* Optional spacing between columns */
  margin-top: 40px;
}

.App-link {
  color: #61dafb;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,8BAA8B,EAAE,gBAAgB;EAChD,SAAS,EAAE,qCAAqC;AAClD;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,kBAAkB,EAAE,6BAA6B;EACjD,gBAAgB;EAChB,mBAAmB,EAAE,qCAAqC;AAC5D;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,8BAA8B,EAAE,gBAAgB;EAChD,UAAU,EAAE,qCAAqC;EACjD,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 10vmin;\n  pointer-events: none;\n}\n\n.header-container {\n  display: grid;\n  grid-template-columns: 1fr 1fr; /* Two columns */\n  gap: 10px; /* Optional spacing between columns */\n}\n\n.header-container {\n  display: flex;\n  justify-content: left;\n}\n\n.logo-container {\n  display: flex;\n  justify-content: right;\n}\n\nh1 {\n  font-size: 30px;\n  color: #1890ff;\n  text-align: center; /* Center align the heading */\n  margin-top: 30px; \n  margin-bottom: 30px; /* Add some space below the heading */\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color:#ffffff;\n  min-height: 25vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n  display: grid;\n  grid-template-columns: 1fr 1fr; /* Two columns */\n  gap: 150px; /* Optional spacing between columns */\n  margin-top: 40px;\n}\n\n.App-link {\n  color: #61dafb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
