import React, { useState } from 'react';
import axios from 'axios';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [registrationMessage, setRegistrationMessage] = useState('');


  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    const url = 'http://43.157.23.126:8080/register';
    const data = {
        username: username,
        password: password,
    };
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      };
    try {
        const response = await axios.post(url, data, { headers });
  
        if (response.status === 200) {
          setRegistrationMessage('You\'ve successfully registered!');
        } else if (response.status === 500) {
          setRegistrationMessage('The user has existed, please try a different username');
        } else {
          setRegistrationMessage('Unknown error');
        }
      } catch (error) {
        console.error('Error registering user:', error);
        setRegistrationMessage('Registration failed. Please try again.');
      }
    console.log('Submitted login form:', username, password);
  };

  return (
    <div className="login-container">
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="username">Username or Email:</label>
                <div className="input-container">
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                        required
                    /><br />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="password">Password:</label>
                <div className="input-container">
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    /><br />
                </div>
            </div>
            <div className="button-container">
                <button className="primary-button">Submit</button>
            </div>
        </form>
        {registrationMessage && <p>{registrationMessage}</p>}
    </div>
  );
};

export default Register;
