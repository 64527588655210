import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [registrationMessage, setRegistrationMessage] = useState('');
  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    try {
      const response = await axios.post('http://43.157.23.126:8080/login', {
        username,
        password,
      });
      console.log(response);
      if ( response.status === 200 ) {
        navigate('/loggedin');
      } else if ( response.status === 403 ) {
        setRegistrationMessage('Username or password are incorrect.');
      } else {
        setRegistrationMessage('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error registering user:', error);
      setRegistrationMessage('Oops');
    }
    console.log('Submitted login form:', username, password);
  };

  const register = (event) => {
    event.preventDefault();
    navigate('/register');
    console.log('Clicked register button, goes to register form');
  };

  return (
    <div className="login-container">
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="username">Username or Email:</label>
                <div className="input-container">
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                        required
                    /><br />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="password">Password:</label>
                <div className="input-container">
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    /><br />
                </div>
            </div>
            <div className="button-container">
                <button className="primary-button">Login</button>
            </div>
            <div className="button-container">
                <button className="secondary-button" onClick={register}>Register</button>
            </div>
        </form>
        {registrationMessage && <p>{registrationMessage}</p>}
    </div>
  );
};

export default Login;
