import React, { useState } from 'react';

const Login = () => {
  return (
    <div className='text-container'>
        Congrats. You've logged in. It's done!
    </div>
  );
};

export default Login;